
import { IResponseData } from "@/models/common";
import { IDcotorListItem } from "@/models/doctor";
import { IOnlineCarePurpose } from "@/models/onlineCare";
import {
  IRequestCardInfo,
  IRequestListItem,
  RequestKindTypes,
  RequestStatuses,
} from "@/models/request";
import { ISecondOpinionPurpose } from "@/models/secondOpinion";
import $API from "@/services";
import { getDoctor } from "@/services/doctor";
import { AxiosResponse } from "axios";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  Ref,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";
import RequestInfo from "./RequestInfo";
import DoctorItem from "@/components/common/Doctors/DoctorItem.vue";
import RequestProgress from "@/components/common/Request/RequestProgress.vue";
import RequestProgressDesc from "@/components/common/Request/RequestProgressDescription.vue";
import DateFormat from "@/utils/date-format";
import FileList from "@/components/file/FileList.vue";

export default defineComponent({
  name: "Request View",
  emits: ["CancelRequest", "deletedFile"],
  props: {
    id: {
      type: String,
      required: true,
    },
    purpose: {
      type: Object as PropType<ISecondOpinionPurpose | IOnlineCarePurpose>,
      required: true,
    },
    kind: {
      type: String as PropType<RequestKindTypes>,
      required: true,
    },
    isQuick: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      id: props.id,
      purpose: props.purpose,
      kind: props.kind,
      item: {} as IRequestListItem,
      doctor: {} as IDcotorListItem,
      isLoading: true,
      isQuick: props.isQuick,
    });

    const { t } = useI18n({ useScope: "global" });

    const onCancel = () => {
      emit("CancelRequest", state.id);
    };

    const cardInfo: Ref<IRequestCardInfo> = ref({} as IRequestCardInfo);
    const requestKind = computed(() =>
      state.kind === RequestKindTypes.OnlineCare
        ? "온라인 대면진료"
        : "2차 소견"
    );

    const insertDateTime = computed(() => {
      return `${
        DateFormat.splitDatetime(state.item.InsertDateTime.valueOf()).date
      } 신청`;
    });

    const deletedFile = () => {
      emit("deletedFile");
    };

    const scheduledDate = computed(() => {
      const date = state.item.ScheduledDateTime
        ? state.item.ScheduledDateTime
        : state.item.DesiredDateTime1;

      return `${DateFormat.splitDatetimeStringToString(date.valueOf())}`;
    });

    onMounted(async () => {
      if (state.purpose.doctorKey) {
        state.doctor = await getDoctor(state.purpose.doctorKey.toString());
      }

      console.log(state.doctor);

      const res: AxiosResponse<IResponseData<IRequestListItem>> =
        await $API.RequestService.getRequestListItem(Number.parseInt(state.id));
      const {
        data: { ResultData },
      } = res;

      if (!Array.isArray(ResultData)) state.item = ResultData;

      cardInfo.value = RequestInfo(state, t, onCancel);

      state.isLoading = false;
    });
    return {
      state,
      cardInfo,
      requestKind,
      insertDateTime,
      RequestStatuses,
      scheduledDate,
      deletedFile,
      RequestKindTypes,
    };
  },
  components: {
    DoctorItem,
    RequestProgress,
    RequestProgressDesc,
    FileList,
  },
});
