
import { useStore } from "@/store";
import { defineComponent, onMounted, reactive } from "vue";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import { AxiosResponse } from "axios";
import { IResponseData, RouterName, StatusTexts } from "@/models/common";
import {
  ICancelRequestParam,
  IRequest,
  RequestStatuses,
  RequestTypes,
} from "@/models/request";
import $API from "@/services";
import { IFileListItem } from "@/models/file";
import { RequestKindTypes } from "@/models/request";
import RequestView from "@/components/common/Request/RequestView.vue";
import { IOnlineCarePurpose } from "@/models/onlineCare";
import { useConfirm } from "primevue/useconfirm";
import router from "@/router";
import { useI18n } from "vue-i18n";
import CustomConfirmDialog from "@/components/primevueCustom/ConfirmDialog.vue";

export default defineComponent({
  name: "Online Care Request View",
  props: {
    id: String,
  },
  setup(props) {
    const state = reactive({
      id: props.id,
      isLoading: true,
      purpose: {
        doctorKey: null as Number | null,
        other: "",
        files: null as Array<IFileListItem> | null,
      } as IOnlineCarePurpose,
      requestStatus: null as RequestStatuses | null,
      isQuick: false,
    });

    const store = useStore();

    const getRequestDoctor = async (resultData: IRequest) => {
      state.purpose.doctorKey = resultData.DesiredDrKey;
    };

    const getRequest = async (id: string) => {
      const res: AxiosResponse<IResponseData<IRequest>> =
        await $API.RequestService.getRequest(Number.parseInt(id));
      const {
        data: { ResultData },
      } = res;
      if (!Array.isArray(ResultData)) {
        state.purpose.other =
          ResultData.ChiefComplaint?.toString() ??
          ResultData.PurposeOthers?.toString() ??
          "";
        state.requestStatus = ResultData.RequestStatus ?? null;
        state.isQuick = ResultData.RequestType === RequestTypes.QUICK;
        await getRequestDoctor(ResultData);
      }
    };

    const getFiles = async (id: string) => {
      const ResultData = await $API.FileService.getFile(id);
      if (!Array.isArray(ResultData)) return;
      state.purpose.files = ResultData;
    };

    const getData = async () => {
      try {
        state.isLoading = true;
        store.commit(CommonMutationTypes.SET_IS_LODING, true);
        if (!!state.id && Number.parseInt(state.id)) {
          await getRequest(state.id);
          await getFiles(state.id);
        }
      } catch (e) {
        console.error(e);
        store.commit(CommonMutationTypes.FILE_UPDATE, false);
      } finally {
        store.commit(
          CommonMutationTypes.SET_IS_LODING,
          store.getters.getIsFileUpdate
        );
        state.isLoading = false;
      }
    };

    const { t } = useI18n({ useScope: "global" });

    const requestcancelConfirm = useConfirm();
    const cancelConfirm = (requestKey: number) => {
      requestcancelConfirm.require({
        header: "예약취소",
        message: "해당 예약을 취소하시겠습니까? 취소 후 복구가 불가능합니다.",
        acceptClass: "p-button-danger",
        acceptLabel: t("Yes"),
        rejectLabel: t("No"),
        group: "cancel",
        accept: async () => {
          const cancelRequestParam: ICancelRequestParam = {
            RequestKey: requestKey,
          };
          const res: AxiosResponse = await $API.RequestService.cancelRequest(
            cancelRequestParam
          );
          if (res.statusText === StatusTexts.OK || res.status === 200) {
            router.push(RouterName.ONLINE_CARE);
          }
          requestcancelConfirm.close();
        },
        reject: () => {
          requestcancelConfirm.close();
        },
      });
    };

    const CancelRequest = (requestKey: number) => {
      cancelConfirm(requestKey);
    };

    onMounted(() => {
      getData();
    });

    return { state, RequestKindTypes, cancelConfirm, CancelRequest };
  },
  components: {
    RequestView,
    CustomConfirmDialog,
  },
});
