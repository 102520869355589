/* eslint-disable no-case-declarations */
import { RouterName } from "@/models/common";
import { IRequestCardInfo, IRequestListItem, RequestKindTypes, RequestStatuses, ResponseStatuses } from "@/models/request";
import DateFormat from "@/utils/date-format";

const RequestInfo = (state:{item: IRequestListItem, kind:RequestKindTypes },t:any, onCancel: () =>void) => {
  const val: IRequestCardInfo = {
    state: "",
    message: "",
    style: "",
    buttons: [] as Array<any>,
  };
  
  const cancel = {
    label: "취소",
    type: "button",
    path: onCancel,
    style: "-default",
  };
  const path = {
    //수정
    edit: () => {
      return {
        name:
          state.kind === RequestKindTypes.OnlineCare
            ? RouterName.ONLINE_CARE_REQUEST_EDIT
            : RouterName.SECOND_OPINION_REQUEST_EDIT,
        params: { id: state.item.RequestKey },
      };
    },
    
    //회신
    response: () => {
      return {
        name:
          state.kind === RequestKindTypes.OnlineCare
            ? RouterName.ONLINE_CARE_RESPONSE
            : RouterName.SECOND_OPINION_RESPONSE,
        params: { id: state.item.RequestKey },
      };
    },
    //화상진료
    video: () => {
      return {
        name: RouterName.ONLINE_CARE_VIDEO,
        params: { id: state.item.RequestKey },
      };
    },
    //처방전
    filePX: () => {
      return {
        name: RouterName.ONLINE_CARE_PX,
        params: { id: state.item.RequestKey },
      };
    },
  };
  switch (state.item.RequestStatus) {
    case RequestStatuses.Requested: //취소 수정 가능    
      state.kind === RequestKindTypes.OnlineCare
        ?
          !!state.item.RequestType && state.item.RequestType > 0 ?
          ((val.state = t("RequestedQuick")),
          (val.message = "빠른 진료 신청")) :
         ((val.state = t("Requested")),
          (val.message = "평균 1시간 이내 확정"))
        : ((val.state = t("ForOpinion")),
          (val.message = "결제확인 후 접수가 완료됩니다."));
      val.style = "-pelorous -medium";
      val.buttons = new Array();
      val.buttons.push(cancel);
      val.buttons.push({
        label: "수정",
        type: "link",
        path: path.edit,
        style: "-default",
      });
      break;

    case RequestStatuses.Scheduled: //취소, 온라인케어는 화상진료 가능
      val.buttons?.push(cancel);
      state.kind === RequestKindTypes.OnlineCare
        ? ((val.state = t("Scheduled")),
          (val.message =
            "D-" +
            DateFormat.durationDate(
              state.item.ScheduledDateTime?.valueOf() ?? ""
            ).toString()),
          val.buttons?.push({
            label: "화상진료",
            type: "link",
            path: path.video,
            style: "-royalBlue",
          }))
        : ((val.message = "3~7일 소요"), (val.state = "결제확인"));

      val.style =
      state.kind === RequestKindTypes.OnlineCare
          ? "-royalBlue -bold"
          : "-royalBlue -medium";
      break;

    case RequestStatuses.Completed: //회신, 온라인케어는 처방전 확인
      val.buttons?.push({
        label: "회신",
        type: "link",
        path: path.response,
        style: "-royalBlue",
      });
      state.item.ResponseStatus === ResponseStatuses.Refused
        ? ((val.state = t("Refused")), (val.style = "-brinkPink"))
        : ((val.state = t("Completed")), (val.style = "-royalBlue"));

      const regFileCount = Number.parseInt(state.item.RegFileCount ? state.item.RegFileCount.valueOf() : "0");
      const reguserFileCount = Number.parseInt(state.item.RegUserFileCount ? state.item.RegUserFileCount.valueOf() : "0");
      const preFileCount = state.item.PreFileCount;
      
      if(regFileCount - reguserFileCount > 0 || !!preFileCount){
        val.buttons?.push({
          label: `처방전 확인`,
          type:"link",
          path: path.filePX,
          style:"-waikawaGrey"
        })
      }

      break;

    case RequestStatuses.Canceled:
      val.state = t("Canceled");
      val.style = "-brinkPink";
      break;
  }

  return val;
};

export default RequestInfo;