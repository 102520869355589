
import Chip from "primevue/chip";
import { IRequestCardInfo } from "@/models/request";
import { defineComponent, PropType, ref } from "vue";

export default defineComponent({
  name: "Request Progress",
  props: {
    cardInfo: {
      type: Object as PropType<IRequestCardInfo>,
      required: true,
    },
  },
  setup(props) {
    const card = ref(props.cardInfo);

    return { card };
  },
  components: {
    Chip,
  },
});
