import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequestView = _resolveComponent("RequestView")
  const _component_CustomConfirmDialog = _resolveComponent("CustomConfirmDialog")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (!_ctx.state.isLoading)
      ? (_openBlock(), _createBlock(_component_RequestView, {
          key: 0,
          purpose: _ctx.state.purpose,
          kind: _ctx.RequestKindTypes.OnlineCare,
          id: _ctx.state.id ?? '',
          isQuick: _ctx.state.isQuick,
          onCancelRequest: _ctx.CancelRequest
        }, null, 8, ["purpose", "kind", "id", "isQuick", "onCancelRequest"]))
      : _createCommentVNode("", true),
    _createVNode(_component_CustomConfirmDialog, { group: "cancel" })
  ], 64))
}